import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
// import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import {blobToBase64} from 'base64-blob'


import styled from 'styled-components';
import {ReactSVG} from 'react-svg';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import uploadIcon from '../../../assets/icons/upload.svg';
import close from '../../../assets/icons/close.svg';

import Text from '../../typography';
import {theme} from '../../../themes/default-theme';
import Cta from '../../ui/Cta';
import Icon from '../../ui/Icon';

const getColor = (props) => {
  if (props.isDragAccept) {
    return theme.palette.greyOpacity
  }
  if (props.isDragActive) {
    return theme.palette.greyOpacity
  }
  return theme.palette.lightGrey
}

const DesktopContainer = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${theme.palette.middleGrey};
  border-style: solid;
  background-color: ${props => getColor(props)};
  outline: none;
  overflow: hidden;
  display: none;

  ${theme.breakpoints.up('md')} {
    display: flex;
  }
`;

const MobileContainer = styled.div`

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

const UploadIcon = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`

const FilesContainer = styled.div`
  overflow: hidden
`

const Files = styled.div`
  margin: 25px -3px 0;
`

const FileItem = styled.div `
  width: 50%;

  ${theme.breakpoints.up('md')} {
    width: 33.3333%;
  }
  display: inline-block;
  padding: 3px;
  position: relative;
`

const FileRemove = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
`

const FileItemNoPreview = styled.div`
  padding-top: 66.66%;
  height: 0;
  background-color: ${theme.palette.lightGrey};
  border: 1px solid ${theme.palette.middleGrey};
`

const FileItemNoPreviewText = styled.div`
  display: flex;
  position: absolute;
  padding: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const FileItemImg = styled.div `
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  padding-top: 66.66%;
  height: 0;
  border: 1px solid ${theme.palette.middleGrey};
`

const TextContainer = styled.div`
  margin: 18px 0 0;
`

const BasicTextContainer = styled.div`
  margin: 18px 0 0;
  ${theme.breakpoints.up('md')} {
    display: inline-block;
    margin: 0 0 0 18px;
    vertical-align: middle;
  }
`

const BasicCtaContainer = styled.div`
  ${theme.breakpoints.up('md')} {
    display: inline-block;
    vertical-align: middle;
    min-width: 200px;
    button {
      width: 100%;
    }
  }
`

const Label = styled.div`
  margin-bottom: 7px;
`

const FileUpload = (props) => {
  const [files, setFiles] = useState(props.defaultValue);
  const [error, setError] = useState(null);
  const {
    uniqueId
  } = props
  // const [dropFiles, onDrop] = useState([]);

  // useEffect(() => {
  //   let newFiles = [];

  //   for (const file of dropFiles) {
  //     const blob = new Blob([file], {type: file.type});
  //     const img = URL.createObjectURL(blob);

  //     newFiles.push({
  //       file: file,
  //       uniqueId: uuidv4(),
  //       // preview: img
  //     });
  //   }

  //   setFiles([
  //     ...files,
  //     ...newFiles
  //   ]);

  // }, [dropFiles]);

  // const readFile = (file) => {
  //   return new Promise((resolve, reject) => {
  //     var fr = new FileReader();  
  //     fr.onload = () => {
  //       resolve(fr.result)
  //     };
  //     fr.readAsDataURL(file);
  //   });
  // }

  const onDrop = async (acceptedFiles) => {

    let newFiles = [];

    console.log('acceptedFiles', acceptedFiles);

    for (const file of acceptedFiles) {

      // const preview = await readFile(file);
      const blob = new Blob([file], {type: file.type});

      await blobToBase64(blob).then(function (str) {
        newFiles.push({
          file: file,
          uniqueId: uuidv4(),
          preview: str,
          // preview: img
        });
      })

      if (props.individualS3Upload) {
        console.log('Post to s3:', newFiles);
      }

      // reader.onload = () => {
      //   console.log('reader', reader);
      // }

      // reader.readAsDataURL(file);

      // console.log('file', file);

      // const blob = new Blob([file], {type: file.type});
      // const img = URL.createObjectURL(blob);
      // img = URL.createObjectURL(blob);

      // newFiles.push({
      //   file: file,
      //   uniqueId: uuidv4(),
      //   preview: null,
      //   // preview: img
      // });
    }
    

    setFiles([
      ...files,
      ...newFiles
    ]);
  }

  const onDropRejected = (error, event) => {
    console.log('error', error);
    console.log('event', event);
  }

  const handleRemove = (index) => {
    const newState = produce(files, draft => {
      draft.splice(index, 1);
    });
    setFiles(newState);
    if (props.individualS3Upload) {
      console.log('@TODO: delete from s3?');
    }
  }

  useEffect(() => {
    props.onChange(files);
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview));
  },[files])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    ...props.uploadProps,
    onDrop,
    onDropRejected,
    disabled: files.length >= props.maxFiles
  });

  return (
    <div>
      {props.basicUi ? 
        <>
          {props.label &&
            <Label>
              <Text>{props.label}</Text>
            </Label>
          }
          <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <BasicCtaContainer>
              <Cta outline inline secondary disabled={files.length >= props.maxFiles}>Upload</Cta>
            </BasicCtaContainer>
            <BasicTextContainer>
              <Text variant="body2">{props.acceptableText}</Text>
              <Text variant="body2">{props.maxFileText}</Text>
            </BasicTextContainer>
          </div>
        </>
      :
      <>
        {props.label &&
          <Label>
            <Text variant="body1">{props.label}</Text>
          </Label>
        }

        <div {...getRootProps()}>
          
            <div>
              <input {...getInputProps()}/>
              <DesktopContainer isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
                <Text variant="subtitle1" uppercase colour="charcoal">{props.text}</Text>
                <UploadIcon>
                  <ReactSVG src={uploadIcon} />
                </UploadIcon>
                <Cta outline inline secondary>or choose a file</Cta>
              </DesktopContainer>

              <MobileContainer>
                <Cta outline inline secondary>Choose a file</Cta>
              </MobileContainer>

            </div>
          
          
        </div>
        
        <TextContainer>
          <Text variant="body2">{props.acceptableText}</Text>
          <Text variant="body2">{props.maxFileText}</Text>
        </TextContainer>
      </>
      }

      {files.length > 0 &&
        <FilesContainer>
          <Files>
            {files.map((item, index) => (
              <FileItem key={item.uniqueId}>
                <FileRemove>
                  <Icon onClick={() => handleRemove(index)} skyBlue small>
                    <ReactSVG src={close} />
                  </Icon>
                </FileRemove>
                {item.type === 'application/pdf' ? 
                  <FileItemNoPreview>
                    <FileItemNoPreviewText>
                      <Text variant="body2" align="center">{item.name}</Text>
                    </FileItemNoPreviewText>
                  </FileItemNoPreview>
                :
                <FileItemImg bg={item.preview}/>
                }
              </FileItem>
            ))}
          </Files>
        </FilesContainer>
      }
    </div>
  );
}

FileUpload.propTypes = {
  uploadProps: propTypes.object,
  text: propTypes.string,
  acceptableText: propTypes.string,
  maxFileText: propTypes.string,
  onChange: propTypes.func,
  label: propTypes.string,
  defaultValue: propTypes.array,
  basicUi: propTypes.bool,
  individualS3Upload: propTypes.bool,
  maxFiles: propTypes.number
}

FileUpload.defaultProps = {
  uploadProps: {
    accept: '.jpg,.jpeg,.png,.pdf', // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
    multiple: false
  },
  defaultValue: [],
  text: 'Drag and drop your photo',
  acceptableText: 'Acceptable file formats: jpg, png, pdf',
  maxFileText: 'Max file size: 5mb',
  onChange: () => {},
  label: 'Upload any supporting photos',
  basicUi: false,
  individualS3Upload: false,
  maxFiles: 100
}

export default FileUpload;